import React from "react";

function App() {
    return (
        <div className="App">
            <div className={ "container" }>
                <img src={ require( "./capstone.png" ) } className={ "logo" } alt={ "Capstone" }/>
                <p>The bonus content for Capstone4D books is no longer available. We hope that you continue to find enjoyment in the content presented within the book.</p>
                <p>If you have questions, please reach out to our Customer Service team via email at <a href={ "mailto:customerservice@capstonepub.com." }>customerservice@capstonepub.com</a>.</p>
            </div>
        </div>
    );
}

export default App;
